<template>
  <div id="messagebox" @click.stop="$emit('updataMsg')">
    <div
      class="popover"
      :style="topClass"
      v-if="!detailStatus"
      @click.stop="''"
    >
      <el-tabs
        type="card"
        size="mini"
        v-model="currentTab"
        @tab-click="getList(1)"
      >
        <el-tab-pane label="Match" name="3" ></el-tab-pane>
      </el-tabs>
      <div class="filter" v-if="currentWarning !== '2'">
        <el-checkbox
          label=""
          size="mini"
          @change="selectMsg(0, 1, $event)"
          v-model="selectAll"
        ></el-checkbox>
        <el-tooltip content="Select All" placement="bottom">
          <div slot="content" @click="selectMsg(0, 2)" style="cursor: pointer">
            Select All
          </div>
          <i class="el-icon-more"></i>
        </el-tooltip>
        <i
          class="el-icon-message"
          @click="readMsg(0, 1)"
        ></i>
        <div class="search">
          Match ID:
          <el-input
            size="mini"
            v-model="standardMatchId"
            type="text"
            maxlength="18"
            clearable
            placeholder=""
            @change="getList"
          ></el-input>
          &nbsp; Third Match ID:
          <el-input
            size="mini"
            v-model="matchId"
            type="text"
            maxlength="18"
            clearable
            placeholder=""
            @change="getList"
          ></el-input>
        </div>
      </div>
      <div class="list" :class="{ match: 1 }" v-if="list.length">
        <ul v-loading="loading">
          <li v-for="(item, index) in list" :key="index">
            <el-checkbox
              label=""
              @change="selectMsg(item)"
              :disabled="item.valid !== 0"
              :value="selectList.includes(item.id)"
            ></el-checkbox>
            <div class="info">
              <p>
                <span class="title">{{item.opType === 12? `[${originMapping[item.dataSourceCode]}] Official time changed` : `[${originMapping[item.dataSourceCode]}] ` + (item.opType === 10 ? 'Home/Away team changed': `Match status changed to ${item.opType === 13? 'postponed': item.opType === 14? 'upcoming': item.opType === 15 ? 'live': 'cancelled'}`)}}</span>
                <span class="time"
                  >Posted on: {{
                    (now - item.createTime) | delayTime
                  }} ago</span
                >
              </p>
              <p class="match-info">
                <span>[{{ item.standardMatchId }}]</span>
                <span>{{ item.sourceMatchId }}</span>
                <span>{{ item.sport }}</span>
                <span>{{ item.region }}</span>
                <span>{{ item.tournament }}</span>
                <span>{{ item.beginTime | formatTime }}</span>
                <i
                  class="el-icon-message"
                  v-if="!item.valid"
                  @click="readMsg(item)"
                ></i>
                <i class="name" v-if="item.opUname">{{item.opUname}}</i>
              </p>
              <div
                class="name"
                v-if="item.remark"
              >
              <span v-if="item.opType === 12">{{item.remark | formatTime}}</span>
              <span v-else>{{
                  item.remark
                }}</span>
                
              </div>
              <!-- <p class="more" @click="openMsg(item)">Read more here</p> -->
            </div>
          </li>
        </ul>
      </div>
      <div v-if="!list.length && currentWarning !== '2'" class="empty">
        <span>No Data</span>
      </div>
      <div class="btn" >
        <el-pagination :total="total" :current-page="current" :page-size="size" @size-change="handleChange('size', $event)" @current-change="handleChange('current', $event)" layout="total, sizes, prev, pager, next, jumper" ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getNotification, readNotification, readAllNotification } from '@/service/index'
import { originMapping } from '@/utils/constants'
import { mapState } from 'vuex'
export default {
  name: 'messagebox',
  data () {
    return {
      originMapping,
      currentWarning: '1',
      currentTab: '3',
      status: false,
      detailStatus: false,
      topClass: '',
      current: 1,
      size: 20,
      matchId: '',
      standardMatchId: '',
      list: [],
      details: '',
      isMaxLoss: false,
      total: 0,
      now: +new Date(),
      isAll: false,
      loading: true,
      selectList: [],
      channelId: '',
      channelList: [],
      selectAll: false,
      titleObj: {
        1: 'Max Loss Pre day',
        2: 'Max Loss Pre match'
      }
    }
  },
  mounted () {
   
  },
  computed: {
   ...mapState('user', ['userInfo']),
   userName() {
    return this.userInfo.name
   },
   userId() {
    return this.userInfo.userId
   }
  },
  created () {
    this.getList(1)
  },
  filters: {
     delayTime:(e) => {
        if (!e) {
          return e
        } else if (e <= 1 * 60 * 1000) {
          return Math.round(e / 1000) + 'sec'
        } else if (e <= 60 * 60 * 1000 && e > 1 * 60 * 1000) {
          return Math.round(e / (60 * 1000)) + 'min'
        } else if (e > 60 * 60 * 1000 && e <= 24 * 60 * 60 * 1000) {
          return Math.round(e / (60 * 60 * 1000)) + 'hrs'
        } else if (e > 24 * 60 * 60 * 1000) {
          return Math.round(e / (24 * 60 * 60 * 1000)) + 'day'
        }
      }
  },
  methods: {
    close,
    handleChange(type, e) {
      this[type] = e
      this.getList()
    },
    selectMsg (row, selectAll, e) {
      if (selectAll === 1) {
        if (this.selectList.length < this.list.filter(i => i.valid === 0).length) {
          this.selectList = this.list.filter(i => i.valid === 0).map((item) => item.id)
        } else {
          this.selectList = []
        }
        this.selectAll = e
      } else if (selectAll === 2) {
        this.selectList = this.list.filter(i => i.valid === 0).map((item) => item.id)
        this.selectAll = true
      } else {
        const index = this.selectList.findIndex((e) => e === row.id)
        if (index > -1) {
          this.selectList.splice(index, 1)
        } else {
          this.selectList.push(row.id)
        }
      }
      this.isAll = selectAll === 2
    },
    readMsg (row, isList) {
      if (!this.isAll && isList && this.selectList.length === 0) {
        return
      }
      const msgIdList = isList ? this.selectList : [row.id];
      (this.isAll ? readAllNotification({userName: this.userName,userId: this.userId}) : readNotification({ids: msgIdList,userName: this.userName,userId: this.userId})).then((res) => {
        if (res.code === 0) {
          this.$message.success('Success')
          if(this.selectAll) {
            this.selectAll = false
          }
          if(this.selectList.length) {
            this.selectList = []
          }
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
    },
    replaceTiem (content, msgType) {
      if (!content) {
        return
      }
      if (msgType === 1) {
        return `${
          JSON.parse(content)[0] ? JSON.parse(content)[0].traderName : ''
        } want to transfer some match to you！`
      } else if (msgType === 2) {
        return `${
          JSON.parse(content)[0] ? JSON.parse(content)[0].traderName : ''
        } rejected some match you transferred！`
      } else if (msgType === 3) {
        let msg = JSON.parse(content).message
        let beginTime = +JSON.parse(content).beginTime
        return msg ? msg.replace(/\$time/g, parseTime(beginTime)) : ''
      } else if (msgType === 4) {
        return `${
          JSON.parse(content)[0] ? JSON.parse(content)[0].traderName : ''
        } want to release some match，there will be no trader in this match, please assign first!`
      } else {
        return content.replace('"', '').replace('"', '')
      }
    },
    hideMsg () {
      this.$emit('updataMsg')
    },
    load () {
      this.current++
      this.getList()
    },
    openMsg (item) {
      this.details = item
      this.detailStatus = true
    },
    getList (init, silence) {
      this.loading = true
      this.now = +new Date()
      if (init) {
        this.current = 1
      }
      let pramas = {
        current: this.current,
        size: this.size,
      }
      if (this.matchId) {
        pramas.sourceMatchId = this.matchId
      }
      if (this.standardMatchId) {
        pramas.standardMatchId = this.standardMatchId
      }
      getNotification(pramas).then(res => {
        if(res.code === 0) {
          console.log(res)
          const {records, total} = res.data || {}
          this.list = records
          this.total = total
        }
      }).finally(e => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-dialog__body{
    padding-top: 0!important;
  }
#messagebox {
  // position: fixed;
  width: 100%;
  height: 70vh;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  .el-icon-close {
    font-size: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #999;
    cursor: pointer;
    z-index: 999;
  }
  .risk-info{
    span {
      font-weight: bold;
    }
  }

  .popover {
    width: 100%;
    height: 100%;
    background-color: #fff;
    //   border: 1px solid #E4E7ED;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    z-index: 99999;
    position: relative;
    z-index: 9;
    // padding-top: 20px;
    .filter {
      display: flex;
      align-items: center;
      height: 50px;
      margin: 0 2px;
      box-shadow: 0px 1px 0px #dedfe4;
      .el-icon-more {
        margin: 0 8px;
        transform: rotate(90deg)
      }
      i {
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
      }
      .search {
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;
        color: #333333;
        .el-input {
          width: 100px;
          margin-left: 10px;
        }
      }
    }
    .header {
      height: 0.47rem;
      background: #2f4162;
      line-height: 0.47rem;
      p {
        font-size: 0.14rem;
        color: #ffffff;
        display: flex;
        justify-content: left;
        align-items: center;
        i {
          font-size: 0.22rem;
          margin: 0 0.12rem 0 0.16rem;
         
        }
      }
    }
    .empty {
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      span{
       margin-top: 50%;
      }
    }
    .list {
      height: 100%;
      padding: 0 2px;
      border-bottom: 1px solid #e4e7ed;
      overflow-y: auto;
      ul {
        padding-bottom: 100px;
      }
      &.match ul {
        padding-bottom: 150px;
      }
      li {
        border-bottom: 1px solid #eef1f3;
        // min-height: 1rem;
        padding: 10px 0;
        list-style-type: none;
        text-align: left;
        display: flex;
        .info {
          margin-left: 10px;
          width: 100%;
        }
        .match-info {
          color: #333;
          line-height: 26px;
          span {
            &:not(:last-of-type)::after {
              content: '|';
              color: #d1d3d9;
              margin: 0 5px;
            }
          }
          i {
            float: right;
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
            margin-right: 10px;
            color: #606266;
            font-style: normal;
            &.name {
              font-weight: normal;
            }
          }
        }
        .name {
          color: #0184b8;
          font-weight: bold;
          font-size: 13px;
        }
        .title {
          font-size: 16px;
          color: #000000;
          margin-right: 10px;
          margin-bottom: 20px;
        }
        p {
          text-align: left;
          line-height: normal;
        }
        .time {
          font-size: 0.1rem;
          color: #999999;
          float: right;
        }
        .msg {
          font-size: 0.12rem;
          color: #333333;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          i {
            float: right;
          }
        }
        .more {
          font-size: 0.1rem;
          color: #0184b8;
          // margin-top: .1rem;
          cursor: pointer;
        }
      }
    }
    .btn {
      padding-top: 10px;
      height: 30px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      border-top: 1px solid #e4e7ed;
      // padding: 0 20px;
      z-index: 9;
      /deep/ .slot {
        width: 0;
      }
      /deep/ .pagination {
        width: 90%;
      }
    }
  }
  /deep/ .el-tabs {
    margin: 0 0.16rem;
    height: 30px;
    .el-tabs__nav {
      border: none;
    }
    .el-tabs__item {
      width: 110px;
      height: 30px;
      line-height: 30px;
      background: #e1e8ee;
      font-size: 13px;
      font-weight: normal;
      margin-right: 10px;
      border: none;
      text-align: center;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      &.is-active {
        background: #0184b8;
        color: #fff;
      }
    }
  }
  .msg-details {
    width: 6.55rem;
    // height: 2.7rem;
    background: #ffffff;
    border: 1px solid #e9f0f6;
    box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.0946514);
    border-radius: 5px;
    position: absolute;
    top: 50%;
    margin-top: -1.45rem;
    left: 50%;
    margin-left: -3.275rem;
    padding: 0.2rem 0.3rem;
    p {
      text-align: left;
      line-height: normal;
    }
    .close {
      font-size: 24px;
      position: absolute;
      right: 27px;
      top: 20px;
      color: #9b9b9b;
    }
    .title {
      font-size: 0.2rem;
      color: #000;
    }
    .center {
      width: 5.88rem;
      min-height: 1rem;
      border: 1px solid #dedfe4;
      border-radius: 5px;
      margin-top: 0.1rem;
      padding: 0.2rem;
      .flexBox {
        display: flex;
        margin-bottom: 0.1rem;
        p {
          margin-right: 0.22rem;
          font-size: 0.13rem;
          color: #333333;
          span {
            font-size: 0.13rem;
            font-weight: 500;
          }
        }
      }
      .flexBox:last-child {
        margin-bottom: 0;
      }
      .offline {
        font-size: 16px;
      }
    }
    .btn {
      height: 0.3rem;
      margin-top: 0.2rem;
      button {
        float: right;
        height: 0.3rem;
        width: 1rem;
        padding: 0;
        line-height: 0.3rem;
        margin-left: 0.1rem;
        color: #fff;
        background: #00a791;
        border-radius: 3px;
        border: 0;
      }
      button:nth-child(2) {
        background: #afbecc;
      }
    }
  }
}
</style>
